<template>
  <v-container fluid>
    <v-row class="pl-16">
      <v-col cols="4" class="pl-14">
        <v-row class="mt-3">
          <Menu></Menu>
          <div class="text-h4 mt-3 font-weight-bold">Conclusão</div>
        </v-row>
        <v-row>
          <div class="light-blue-rectangle mt-3"></div>
        </v-row>
      </v-col>

      <v-col cols="8" class="justify-space-around pl-16">
        <v-row>
          <v-col cols="6" class="mt-10 pl-7">
            <date-picker style="max-width: 160px" v-model="params.dataInicio" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data de início"
                         lang="pt-br"></date-picker>
            <date-picker style="max-width: 160px"  v-model="params.dataFinal" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data final"
                         lang="pt-br"></date-picker>
          </v-col>
          <v-col cols="6">
            <v-list-item class="mt-6 justify-end pr-16 mr-16 pt-2">
              <div class="text-center">
                <v-dialog v-model="modal"  transition="dialog-top-transition" max-width="800">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="modal = true" v-bind="attrs" v-on="on">
                      <v-icon title="Filtro">mdi mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Filtros
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row class="justify-space-around pt-2">
                        <v-col cols="5">
                          <v-select v-model="cnpjsSelecionados" :items="cnpjs" item-text="cnpj" label="Laboratórios" multiple chips hint="Selecione os laboratórios que serão exibidos" persistent-hint></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="modal = false">
                        Pesquisar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-list-item>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-9 pl-16">
    <v-col v-for="card in cards" :key="card.title" cols="12" md="6">
      <div class="pl-8 pr-8">
        <h3 class="chart-title">{{ card.title }}</h3>
        <div :id="card.id"></div>
      </div>
    </v-col>
  </v-row>
  </v-container>
</template>


<script>
import { GoogleCharts } from "google-charts";
import api from "@/plugins/api";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import Swal from "sweetalert2";
import Menu from "@/components/Menu.vue";


export default {
  name: "mediaDeDiasPorPatologista",
  components: {Menu, DatePicker},

  data() {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 6);
    let formattedDate = currentDate.toLocaleDateString();
    return {
      media: [],
      mediaDias: [],
      patologista: [],
      anoMes: [],
      cnpjs: [],
      cnpjsSelecionados: [],
      cards: [
        { title: "Média de dias por patologista", id: "mediaDeDiasPorPatologista" },
        { title: "Média de dias mensal", id: "mediaDeDiasMensal" },
        { title: "Média de dias por patologista mensal", id: "mediaDeDiasPorPatologistaMensal" },
        { title: "Percentual de tempo na conclusão por tipo de exame", id: "percentualDeTempoNaConclusão" }
      ],
      primeiroCNPj: null,
      menu1: false,
      menu2: false,
      params: {
        cnpj: [],
        dataInicio: formattedDate,
        dataFinal: new Date().toLocaleDateString('pt-br')
      },
      modal: false
    };
  },

  created() {
    this.listaDeLaboratorios();
    addEventListener("resize", () => {
      this.larguraGraph = document.getElementById('mediaDeDiasMensal').offsetWidth;
      this.drawChartmediaDeDiasPorPatologistaMensal();
      this.drawChartPercentualDeTempoPorTipoDeExame();
      this.drawChartMediaDiasMensal();
      this.drawChartMediaDiasPorPatologista();

    });
  },

  mounted() {
    this.larguraGraph = document.getElementById('mediaDeDiasMensal').offsetHeight;
  },

  watch: {
    'params.dataInicio': 'loadData',
    'params.dataFinal': 'loadData',
    cnpjsSelecionados: {
      handler(newValue) {
        // Verificar se o valor atual é diferente do valor armazenado
        const storedCNPJs = JSON.parse(localStorage.getItem('selectedCNPJs'));
        if (JSON.stringify(newValue) !== JSON.stringify(storedCNPJs)) {
          localStorage.setItem('selectedCNPJs', JSON.stringify(newValue));
          this.loadData();
        }
      },
    },
  },

  methods: {
    listaDeLaboratorios(){
      api.post('api/autenticacao/listaDeLaboratorios', this.params).then(response => {
        this.cnpjs = response.data;
        this.primeiroCNPj = this.cnpjs[0].cnpj
        this.loadData();
      });
    },

    loadData() {
      const selectedCNPJs = localStorage.getItem('selectedCNPJs');

      if (!selectedCNPJs) {
        this.params.cnpj = [this.primeiroCNPj];
        this.cnpjsSelecionados = this.params.cnpj;
      } else {
        this.params.cnpj = JSON.parse(selectedCNPJs);
        this.cnpjsSelecionados = this.params.cnpj
      }


      api.post('api/conclusao/mediaDeDiasPorPatologista', this.params).then(response => {
        this.media = response.data;
        this.drawChartMediaDiasPorPatologista();
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      });

      api.post('/api/conclusao/mediaDeDiasMensal/', this.params).then(response => {
        this.mediaDiasMensal = response.data;
        this.drawChartMediaDiasMensal();
      });

      api.post('/api/conclusao/percentualDeTempoPorTipoDeExame/', this.params).then(response => {
        this.percentualDeTempoPorTipoDeExame = response.data;
        this.drawChartPercentualDeTempoPorTipoDeExame();
      });

      api.post('/api/conclusao/patologistasPorCNPJ/', this.params).then(response => {
        this.patologistasPorCNPJ = response.data;
        api.post('/api/conclusao/mediaDeDiasPorPatologistaMensal/', this.params).then(response => {
          this.mediaDiasPatologistaMensal = response.data;
          this.drawChartmediaDeDiasPorPatologistaMensal();
        });
      });
    },

    drawChartMediaDiasPorPatologista() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Patologista');
        data.addColumn('number', 'Média de Dias');
        data.addColumn({role: "style"})

        const cores = [
          "#2196f3", "#00bcd4", "#009688", "#4caf50",
          "#ff9800", "#ff5722", "#f44336",
          "#673ab7", "#9c27b0", "#e91e63",
          "#ffd54f", "#ffecb3", "#ffcc80",
          "#b2ebf2", "#80deea", "#4dd0e1",
          "#c5cae9", "#7986cb", "#5c6bc0",
        ];
        let indiceCor = 0;

        Object.keys(this.media).forEach(patologista => {
          const item = this.media[patologista];
          item.forEach(item => {
            const cor = cores[indiceCor % cores.length];
            data.addRow([item.patologista, item.mediaDias, cor]);
            indiceCor++
          })
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: { count:0},
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color:'#37474f', count:4},
            baselineColor: 'transparent',
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color:'white', fontSize: '18', bold: true} },
          colors: ["#2196f3","#03a9f4","#00bcd4","#009688","#4caf50","#8bc34a","#cddc39"],
          areaOpacity: 0.24,
          lineWidth: 1,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height:(this.larguraGraph / 3), // example height, to make the demo charts equal size
          width: this.larguraGraph,
          bar: {groupWidth: "40" },
          colorAxis: {colors: ["#3f51b5","#2196f3","#03a9f4","#00bcd4"] },
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions'
        };

        var chart = new GoogleCharts.api.visualization.ColumnChart(
            document.getElementById('mediaDeDiasPorPatologista')
        );
        chart.draw(data, options);
      });
    },

    drawChartMediaDiasMensal() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Ano - Mês');
        data.addColumn('number', 'Média de Dias');

        Object.keys(this.mediaDiasMensal).forEach(anoMes => {
          const items = this.mediaDiasMensal[anoMes];
          items.forEach(item => {
            data.addRow([item.anoMes, item.mediaDias]);
          });
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: { count:0},
            textStyle: { color: '#b0bec5', fontSize: '12'}
          },
          vAxis: {
            gridlines: {color:'#37474f', count:4},
            baselineColor: 'transparent',
            textStyle: { color: 'white', fontSize: '12'}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color:'white', fontSize: '18', bold: true} },
          colors: ["#2196f3","#03a9f4","#00bcd4","#009688","#4caf50","#8bc34a","#cddc39"],
          areaOpacity: 0.5,
          lineWidth: 1,
          chartArea: {
            backgroundColor: "transparent",
            width: '90%',
            height: '70%'
          },
          height:(this.larguraGraph / 3), // example height, to make the demo charts equal size
          width: this.larguraGraph,
          bar: {groupWidth: "40" },
          colorAxis: {colors: ["#3f51b5","#2196f3","#03a9f4","#00bcd4"] },
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions'
        };

        var chart = new GoogleCharts.api.visualization.AreaChart(
            document.getElementById('mediaDeDiasMensal')
        );
        chart.draw(data, options);
      });
    },

    drawChartPercentualDeTempoPorTipoDeExame(){
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Tipo de Exame');
        data.addColumn('number', 'Média de Dias');

        Object.keys(this.percentualDeTempoPorTipoDeExame).forEach(tipoExame => {
          const item = this.percentualDeTempoPorTipoDeExame[tipoExame];
          item.forEach(item => {
            data.addRow([item.tipoExame, item.mediaDiasConclusao]);
          })
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: { count:0},
            textStyle: { color: '#b0bec5', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color:'#37474f', count:4},
            baselineColor: 'transparent',
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color:'white', fontSize: '12'} },
          colors: ["#3f51b5","#2196f3", "#8f3f8d","#03a9f4","#00bcd4","#009688","#4caf50","#8bc34a","#cddc39"],
          areaOpacity: 0.24,
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height:(this.larguraGraph / 3), // example height, to make the demo charts equal size
          width: this.larguraGraph,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle:  {color:'white', style: 'bold' },
          pieHole: 0.45,
          bar: {groupWidth: "40" },
          colorAxis: {colors: ["#3f51b5","#2196f3","#03a9f4","#00bcd4"] },
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions'
        };

        var chart = new GoogleCharts.api.visualization.PieChart(
            document.getElementById('percentualDeTempoNaConclusão')
        );
        chart.draw(data, options);
      });
    },

    drawChartmediaDeDiasPorPatologistaMensal() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Mês da liberação');

        // Itera sobre cada patologista na array de objetos
        Object.keys(this.patologistasPorCNPJ).forEach(patologista => {
          const nomesPatologista = this.patologistasPorCNPJ[patologista].map(obj => obj.patologista);
          nomesPatologista.forEach(nome => {
            data.addColumn('number', nome);
          });
        });

        // Itera sobre a lista de mediaDiasPatologistaMensal
        this.mediaDiasPatologistaMensal.forEach(linha => {
          let rowData = [linha[0]];

          for (let i = 1; i < linha.length; i++) {
            rowData.push(linha[i]);
          }

          data.addRows([rowData]);
        });



        const options = {
          chart: {
            title: 'Média de Dias por Patologista Mensal',
            subtitle: 'Estatísticas por mês',
            hAxis: -1,
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          hAxis: {
            titleTextStyle: {color: '#b0bec5'},
            gridlines: { count:0 },
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color:'#37474f', count:4},
            baselineColor: 'transparent'
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color:'white', fontSize: '12'} },
          colors: ["#3f51b5","#2196f3","#8f3f8d","#00bcd4","#009688","#4caf50","#8bc34a","#cddc39",],
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height:(this.larguraGraph / 3), // example height, to make the demo charts equal size
          width: this.larguraGraph,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle:  {color:'#607d8b' },
          pieHole: 0.9,
          bar: {groupWidth: "40" },
          colorAxis: {colors: ["#3f51b5","#2196f3","#03a9f4","#00bcd4"] },
          backgroundColor: "#32373d",
          datalessRegionColor: '#37474f',
          displayMode: 'regions'

        };

        const chart = new GoogleCharts.api.visualization.LineChart(document.getElementById('mediaDeDiasPorPatologistaMensal'));
        chart.draw(data, options);
      });
    }

  },
};
</script>

<style scoped>
.chart-title {
  color: #fff; /* Cor branca */
  font-weight: bold; /* Negrito */
}
.light-blue-rectangle {
  width: 230px;
  height: 10px;
  background-color: #2196f3;
}

</style>

